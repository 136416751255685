import {PlanningState} from "./state";
import {
    CHANGE_ASSIGNMENT_TECHNICIAN,
    CREATE_NEW_ASSIGNED_SCHEDULE,
    DELETE_ASSIGNED_SCHEDULE,
    DELETE_DAY_ASSIGNED_SCHEDULES,
    DELETE_REPLACEMENT,
    DUPlICATE_ASSIGNED_SCHEDULE,
    FETCH_CURRENT_STATE,
    FETCH_INTERIM_PLANNING_STATE,
    GO_TO_NEXT_WEEK,
    GO_TO_PREVIOUS_WEEK,
    MOVE_ASSIGNMENT_TO_ASSIGNED_SCHEDULE,
    PERSIST_CHANGES,
    PlanningAction,
    SAVE_REPLACEMENT,
    SWAP_ASSIGNMENTS,
    UPDATE_ASSIGNED_SCHEDULE
} from "./actions";
import {PlanningContainer} from "./container";
import deleteDayAssignedSchedules from "./Actions/deleteDayAssignedSchedules";
import {RefreshDirection} from "../repository/backendRepository";
import {InputState, stateDataTransformer} from "../model/DataTransformer/stateDataTransformer";
import {PlanningReducerAction} from "./Actions/action.type";
import {createNewAssignedSchedule} from "./Actions/createNewAssignedSchedule";
import moveAssignmentToAssignedSchedule from "./Actions/moveAssignmentToAssignedSchedule";
import swapAssignments from "./Actions/swapAssignments";
import updateAssignedSchedule from "./Actions/updateAssignedSchedule";
import {deleteAssignedSchedule} from "./Actions/deleteAssignedSchedule";
import saveReplacement from "./Actions/saveReplacement";
import deleteReplacement from "./Actions/deleteReplacement";
import changeAssignmentTechnician from "./Actions/changeAssignmentTechnician";
import duplicateAssignedSchedule from "./Actions/duplicateAssignedSchedule";
import {
    MaxOneAssignmentPerTechnicianPerDayConstraint
} from "../validation/constraints/MaxOneAssignmentPerTechnicianPerDay.constraint";
import {ValidationError} from "../../Generic/validation/validationError.interface";

const PlanningReducer = (state: PlanningState, action: PlanningAction) => {
    const _backendAction = (promise: Promise<InputState>): Promise<PlanningState> => {
        return new Promise((resolve,reject) => {
            promise
                .then(inputState => {
                    const transformedState = stateDataTransformer.transform(inputState);
                    console.log("Transformed State", transformedState);
                    resolve(validateState(transformedState));
                })
                .catch(reject)
        })
    }

    const doPlanningAction = (planningAction: PlanningReducerAction, ...values: any[]): PlanningState => {
        const updates = planningAction(state, ...values)
        if (updates === null) {
            return state
        } else {
            let newState = {
                ...state, changes:
                updates.changes,
                assignedSchedules:
                updates.assignedSchedules,
                errors: updates.errors ? [...updates.errors] : state.errors
            };
            console.log("Updated State", newState);
            return validateState(newState);
        }
    }

    const validateState = (state: PlanningState): PlanningState =>
    {
        const isStateValid = PlanningContainer.validator.validate(
          [
            new MaxOneAssignmentPerTechnicianPerDayConstraint(state)
          ]
        )

        if (isStateValid) {
            return state;
        }

        const allErrors =  PlanningContainer.validator.validationErrors.map((validationError: ValidationError) => {
            return new Error(validationError.message);
        })

        return {
            ...state,
            errors: state.errors === null ? allErrors : [...state.errors, ...allErrors]
        }
    }

    switch (action.type) {
        case FETCH_CURRENT_STATE:
            return _backendAction(PlanningContainer.backendRepository.refreshData(state.week))
        case FETCH_INTERIM_PLANNING_STATE:
            return _backendAction(PlanningContainer.backendRepository.refreshInterimData(state.week))
        case GO_TO_PREVIOUS_WEEK:
            return _backendAction( PlanningContainer.backendRepository.refreshData(state.week, RefreshDirection.Previous))
        case GO_TO_NEXT_WEEK:
            return _backendAction( PlanningContainer.backendRepository.refreshData(state.week, RefreshDirection.Next))
        case DELETE_DAY_ASSIGNED_SCHEDULES:
            return doPlanningAction(deleteDayAssignedSchedules, action.payload)
        case CREATE_NEW_ASSIGNED_SCHEDULE:
            return doPlanningAction(createNewAssignedSchedule, action.payload)
        case UPDATE_ASSIGNED_SCHEDULE:
            return doPlanningAction(updateAssignedSchedule, action.payload.updatedAssignedSchedule, action.payload.oldAssignedSchedule)
        case DELETE_ASSIGNED_SCHEDULE:
            return doPlanningAction(deleteAssignedSchedule, action.payload)
        case DUPlICATE_ASSIGNED_SCHEDULE:
            return doPlanningAction(duplicateAssignedSchedule, action.payload.assignedSchedule, action.payload.duplicateData)
        case MOVE_ASSIGNMENT_TO_ASSIGNED_SCHEDULE:
            return doPlanningAction(moveAssignmentToAssignedSchedule, action.payload.assignment, action.payload.fromAssignedSchedule, action.payload.toAssignedSchedule)
        case SWAP_ASSIGNMENTS:
            return doPlanningAction(swapAssignments, action.payload.assignmentA, action.payload.assignedScheduleA, action.payload.assignmentB, action.payload.assignedScheduleB)
        case CHANGE_ASSIGNMENT_TECHNICIAN:
            return doPlanningAction(changeAssignmentTechnician, action.payload.assignment, action.payload.newTechnician)
        case SAVE_REPLACEMENT:
            return doPlanningAction(saveReplacement, action.payload.assignment, action.payload.replacementAssignment, action.payload.comment)
        case DELETE_REPLACEMENT:
            return doPlanningAction(deleteReplacement, action.payload.fromAssignment)
        case PERSIST_CHANGES:
            console.log("Saving Changes")
            return _backendAction(PlanningContainer.backendRepository.saveChanges(state.week, state.changes))
        default: return state;
    }
}

export default PlanningReducer;
