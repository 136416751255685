import AssignedSchedule from "../model/assignedSchedule.interface";
import Assignment from "../model/assignment.interface";
import Technician from "../model/technician.interface";
import Week from "../model/week.interface";
import {RefreshDirection} from "../repository/backendRepository";
import {DuplicateAssignedScheduleData} from "./Actions/duplicateAssignedSchedule";

export const FETCH_CURRENT_STATE = 'fetch_current_state';
export const FETCH_INTERIM_PLANNING_STATE = 'fetch_interim_planning_state'
export const DELETE_DAY_ASSIGNED_SCHEDULES = 'delete_day_assigned_schedules';
export const PERSIST_CHANGES = 'persist_changes';
export const GO_TO_PREVIOUS_WEEK = 'go_to_previous_week'
export const GO_TO_NEXT_WEEK = 'go_to_next_week'

export const CREATE_NEW_ASSIGNED_SCHEDULE = 'create_new_assigned_schedule'
export const UPDATE_ASSIGNED_SCHEDULE = 'update_assigned_schedule'
export const DELETE_ASSIGNED_SCHEDULE = 'delete_assigned_schedule'
export const DUPlICATE_ASSIGNED_SCHEDULE = 'duplicate_assigned_schedule'
export const MOVE_ASSIGNMENT_TO_ASSIGNED_SCHEDULE = "move_assignment_to_assigned_schedule"
export const SWAP_ASSIGNMENTS = "swap_assignments"
export const CHANGE_ASSIGNMENT_TECHNICIAN = 'change_assignment_technician'

export const SAVE_REPLACEMENT = 'save_replacement'
export const DELETE_REPLACEMENT = 'delete_replacement'

export type PlanningAction =
    { type: typeof FETCH_CURRENT_STATE}
    | {type: typeof FETCH_INTERIM_PLANNING_STATE}
    | {type: typeof DELETE_DAY_ASSIGNED_SCHEDULES, payload: Date}
    | {type: typeof PERSIST_CHANGES }
    | {type: typeof GO_TO_PREVIOUS_WEEK}
    | {type: typeof GO_TO_NEXT_WEEK}
    | {type: typeof CREATE_NEW_ASSIGNED_SCHEDULE, payload: AssignedSchedule}
    | {type: typeof UPDATE_ASSIGNED_SCHEDULE, payload: {updatedAssignedSchedule: AssignedSchedule, oldAssignedSchedule: AssignedSchedule}}
    | {type: typeof DELETE_ASSIGNED_SCHEDULE, payload: AssignedSchedule}
    | {type: typeof DUPlICATE_ASSIGNED_SCHEDULE, payload: {assignedSchedule: AssignedSchedule, duplicateData: DuplicateAssignedScheduleData}}
    | {type: typeof MOVE_ASSIGNMENT_TO_ASSIGNED_SCHEDULE, payload: {assignment: Assignment, fromAssignedSchedule: AssignedSchedule, toAssignedSchedule: AssignedSchedule}}
    | {type: typeof SWAP_ASSIGNMENTS, payload: {assignmentA: Assignment, assignedScheduleA: AssignedSchedule, assignmentB: Assignment, assignedScheduleB: AssignedSchedule}}
    | {type: typeof SAVE_REPLACEMENT, payload: {assignment: Assignment, replacementAssignment: Assignment, comment: string}}
    | {type: typeof DELETE_REPLACEMENT, payload: {fromAssignment: Assignment}}
    | {type: typeof CHANGE_ASSIGNMENT_TECHNICIAN, payload: {assignment: Assignment, newTechnician: Technician}}

export type PlanningActions = {
    fetchCurrentState: () => void,
    fetchInterimPlanningState: () => void,
    deleteDayAssignedSchedules: (d: Date) => void,
    persistChanges: () => void,
    goToPreviousWeek : () => void,
    goToNextWeek : () => void,
    createNewAssignedSchedule: (a: AssignedSchedule) => void,
    updateAssignedSchedule: (updatedAssignedSchedule: AssignedSchedule, oldAssignedSchedule: AssignedSchedule) => void,
    deleteAssignedSchedule: (a: AssignedSchedule) => void,
    duplicateAssignedSchedule: (assignedSchedule: AssignedSchedule, duplicateData: DuplicateAssignedScheduleData) => void,
    moveAssignmentToAssignedSchedule: (assignment: Assignment, fromAssignedSchedule: AssignedSchedule, toAssignedSchedule: AssignedSchedule) => void,
    swapAssignments: (assignmentA: Assignment, assignedScheduleA: AssignedSchedule, assignmentB: Assignment, assignedScheduleB: AssignedSchedule) => void,
    changeAssignmentTechnician: (assignment: Assignment, newTechnician: Technician) => void,
    saveReplacement: ( assignment: Assignment, replacementAssignment: Assignment, comment: string) => void,
    deleteReplacement:  (fromAssignment: Assignment) => void
}

export function createActions(dispatch = ({}: PlanningAction) => {}): PlanningActions {
    return {
        fetchCurrentState: () => dispatch({ type: FETCH_CURRENT_STATE }),
        fetchInterimPlanningState: () => dispatch({ type: FETCH_INTERIM_PLANNING_STATE }),
        deleteDayAssignedSchedules: (d: Date) => dispatch({type: DELETE_DAY_ASSIGNED_SCHEDULES, payload: d}),
        persistChanges: () => dispatch({type: PERSIST_CHANGES}),
        goToPreviousWeek: () => dispatch({type: GO_TO_PREVIOUS_WEEK}),
        goToNextWeek: () => dispatch({type: GO_TO_NEXT_WEEK}),
        createNewAssignedSchedule: (a: AssignedSchedule) => dispatch({type: CREATE_NEW_ASSIGNED_SCHEDULE, payload: a}),
        updateAssignedSchedule: (updatedAssignedSchedule: AssignedSchedule, oldAssignedSchedule: AssignedSchedule) => dispatch({type: UPDATE_ASSIGNED_SCHEDULE, payload: {updatedAssignedSchedule, oldAssignedSchedule}}),
        deleteAssignedSchedule: (a: AssignedSchedule) => dispatch({type: DELETE_ASSIGNED_SCHEDULE, payload: a}),
        duplicateAssignedSchedule: (assignedSchedule: AssignedSchedule, duplicateData: DuplicateAssignedScheduleData) => dispatch({type: DUPlICATE_ASSIGNED_SCHEDULE, payload: {assignedSchedule, duplicateData}}),
        moveAssignmentToAssignedSchedule: (assignment: Assignment, fromAssignedSchedule: AssignedSchedule, toAssignedSchedule: AssignedSchedule) => dispatch({type: MOVE_ASSIGNMENT_TO_ASSIGNED_SCHEDULE, payload: {assignment, fromAssignedSchedule, toAssignedSchedule}}),
        swapAssignments: (assignmentA: Assignment, assignedScheduleA: AssignedSchedule, assignmentB: Assignment, assignedScheduleB: AssignedSchedule) => dispatch({type: SWAP_ASSIGNMENTS, payload: {assignmentA, assignedScheduleA, assignmentB, assignedScheduleB}}),
        changeAssignmentTechnician: (assignment: Assignment, newTechnician: Technician) => dispatch({type: CHANGE_ASSIGNMENT_TECHNICIAN, payload: {assignment, newTechnician}}),
        saveReplacement: (assignment: Assignment, replacementAssignment: Assignment, comment: string) => dispatch({type: SAVE_REPLACEMENT, payload: {assignment, replacementAssignment, comment}}),
        deleteReplacement: (fromAssignment: Assignment) => dispatch({type: DELETE_REPLACEMENT, payload: {fromAssignment}})
    };
}
